<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  mounted(){
    console.log("Client ID "+this.$cookies.get("clientData").clientId)
    if(this.$cookies.get("clientData") && this.$cookies.get("clientData").clientId==159){
    console.log("Client ID "+this.$cookies.get("clientData").clientId)
    $themeConfig.app.appName="AARAF"
      $themeConfig.app.appLogoImage = require('@/assets/images/logo/AARAFA.jpg');
      
    }
  }
}
</script>
